@import "../../styles/_variables";

.ready {
  width: 100%;
  height: 288px;
  background-color: $white;
  margin-bottom: 0 !important;

  h4 {
    font-size: 34px;
    font-weight: $font-weight-black;
    text-transform: uppercase;
    text-align: center;

    margin-bottom: 20px;
  }

  @include flex-center;
  flex-direction: column;
}
