@import "../../styles/_variables";

.footer {
  // bottom: 0;
  // position: absolute;
  background-color: $black;
  height: 215px;
  padding: 50px;
  margin: 0 !important;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  * {
    color: $white;
  }

  .footer-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    line-height: 171%;
    letter-spacing: 0.03em;

    h4 {
      font-weight: $font-weight-bold;
      font-size: $font-size-copy-small;
    }
    p {
    }

    &.full-height {
      justify-content: space-between;
    }
  }
}
