@import "../../styles/_variables";

.site-nav {
  padding: 22px 24px;
  height: 62px;
  width: 100vw;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100000;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);

  .logo {
    width: 135px;
  }

  .nav {
    display: flex;
    align-items: center;

    :not(:first-child) {
      margin-left: 30px;
    }

    p {
      font-size: $font-size-label;
      text-align: right;
      letter-spacing: 0.035em;
      text-transform: uppercase;
      color: $black;
      cursor: pointer;
      transition: color 500ms ease-out;

      &:hover {
        color: $color-blue;
      }
    }
  }
}
