.line-banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1 !important;

  height: 100%;

  canvas {
    opacity: 0.369995;
    // top: 180px;
    top: 230px;
    width: 100vw;
  }
}
