@import "../../styles/_variables";

.site-banner {
  height: 545px;
  margin: 0px !important;

  h1 {
    position: absolute;
    left: 6.09%;
    top: 18.5%;
    width: 530px;
    display: flex;
    flex-wrap: wrap;

    font-weight: $font-weight-black;
    font-size: $font-size-large;
    line-height: 106%;
    letter-spacing: 2px;
    text-transform: uppercase;

    & > * {
      opacity: 0;
      font-weight: $font-weight-black;
      font-size: $font-size-large;
      line-height: 106%;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .tagline {
    display: flex;
    flex-direction: column;

    position: absolute;
    left: 6.23%;
    top: 264px;
    width: 530px;

    h4 {
      opacity: 0;
      font-weight: $font-weight-light;
      font-size: $font-size-copy;
      line-height: 128%;
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;

      & > * {
        font-weight: $font-weight-light;
        font-size: $font-size-copy;
        line-height: 128%;
      }
    }

    .nav {
      display: flex;
      align-items: center;

      & > * {
        opacity: 0;
      }

      :not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  & > * {
    z-index: 10;
  }
}
