@import "./styles/fonts";
@import "./styles/_variables";

body {
  @include flex-center;
  background-color: $brand-background;
}

* {
  font-family: $font-family;
  font-weight: $font-weight-normal;
  font-size: $font-size-body;
  color: $color-dark;
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}
