@import "../../styles/_variables";

.mid-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;

  gap: 40px;

  margin: 40px 0 20px 0 !important;

  h1 {
    font-size: $font-size-large;
    font-weight: $font-weight-black;
    text-transform: uppercase;
    color: $black;
    width: 100%;
    @include flex-center;

    & > * {
      opacity: 0;
      font-size: $font-size-large;
      font-weight: $font-weight-black;
      text-transform: uppercase;
      color: $black;
    }
  }

  .body {
    opacity: 0;
    max-width: 1080px;
    font-weight: $font-weight-light;
    font-size: $font-size-copy-small;
    line-height: 32px;
  }

  .stats {
    display: flex;

    & > * {
      opacity: 0;
    }

    .stat-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      margin: 0 50px;

      h4 {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-blue;
      }

      label {
        font-size: 18px;
        line-height: 22px;
        font-weight: $font-weight-light;
        color: $black;
      }
    }

    .divider {
      margin-top: 20px;
      height: 44px;
      width: 1px;
      background-color: $color-light;
    }
  }

  .arrow-button {
    opacity: 0;
    transition: margin-left 250ms ease-in-out;

    &:hover {
      margin-left: 20px;
    }
  }
}
