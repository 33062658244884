@import "../../styles/_variables";

.graph-banner {
  width: 100%;
  background-color: $white;
  // margin-top: 300px;
  padding: 100px 0;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  gap: 20px;

  .card {
    width: 500px;
    padding: 0;

    transform: unset;
    box-shadow: unset;
    z-index: unset;
    &:hover {
      transform: unset;
      box-shadow: unset;
      z-index: unset;
    }
  }

  .carousel {
    @include flex-center;
    flex-direction: column;

    .content {
      width: 802px;
      height: 321px;
      overflow: hidden;

      & > * {
        position: absolute;
      }
    }

    .nav {
      .left-arrow {
        &:hover {
          transform: scale(1.2);
        }
      }
      .right-arrow {
        margin-left: 16px;
        transform: rotate(180deg);

        &:hover {
          transform: scale(1.2) rotate(180deg);
        }
      }

      img {
        border-radius: 50%;
        transition: transform 250ms ease-in-out;
        cursor: pointer;
      }
    }

    .graph {
      margin: 0 70px;
      .percentage {
        right: 41px;
        font-size: $font-size-copy-small;
        font-weight: $font-weight-bold;
        text-align: right;
      }

      .company-labels {
        p {
          opacity: 0;
          text-align: right;
          font-weight: $font-weight-bold;
          text-transform: uppercase;
          right: 100%;

          &.blue {
            color: #0063ad;
          }
          &.green {
            color: #32a39d;
          }
        }
      }

      .years {
        margin-left: 10px;
        margin-top: -10px;
        display: flex;
        justify-content: space-between;
        p {
          margin-right: 40px;
        }
      }
    }
  }
}
