@import "../styles/_variables";

.App {
  // max-width: 1280px;
  display: flex;
  flex-direction: column;

  width: 100vw;

  & > * {
    &:not(:first-child) {
      margin: 40px 0;
    }
  }

  .cards {
    margin-top: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    z-index: 200;

    .card {
      &:not(:first-child) {
        margin-left: 60px;
      }
    }
  }

  .cards-2 {
    margin: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    z-index: 200;

    .card {
      height: 360px;
      max-width: 700px;
      // padding: 20px;
      background-color: rgba(255, 255, 255, 0);
      box-shadow: unset;
    }

    .graph-icon {
      width: 250px;
    }

    .video-graphic {
      background-color: red;
      width: 234px;
      height: 156px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .thumb-icon {
        width: 234px;
        height: 156px;
      }

      .play-btn-icon {
        width: 50px;
        height: 36px;
      }
    }
  }

  .divider {
    margin: 0 40px;
    height: 270px;
    width: 1px;
    background-color: $color-light;
  }
}
