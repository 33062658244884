$brand-background: #fdfbf9;
// $brand-background: #e0d0c0;
$white: #ffffff;
$black: #0a2349;
$color-dark: #1b284d;
$color-very-dark: #20344c;
$color-teal: #32a39d;
$color-blue: #0063ad;
$color-blue-alpha: rgba($color-blue, 0%);
$color-blue-light: #51b2e9;
$color-blue-light-alpha: rgba($color-blue-light, 0%);
$color-light: #d5dae8;

$font-family: "Verlag";
$font-weight-normal: 325;
$font-weight-light: 300;
$font-weight-bold: 400;
$font-weight-black: 450;

$font-size-body: 16px;
$font-size-label: 14px;
$font-size-large: 50px;
$font-size-card: 39px;
$font-size-copy: 25px;
$font-size-copy-small: 24px;
$font-size-copy-smaller: 20px;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
