@import "../../styles/_variables";

.button {
  @include flex-center;
  height: 40px;
  width: fit-content;
  background-color: $color-blue-light;
  color: $white;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;

  line-height: 19px;
  letter-spacing: 0.03em;

  padding: 0px 30px;
  white-space: nowrap;

  transition: background-color 150ms ease-out, color 250ms ease-out, border 250ms ease-out;

  & > * {
    cursor: pointer;
    user-select: none;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    z-index: 1;
    margin-left: 0 !important;
    background-image: linear-gradient(
      210deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 90%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:hover {
    &::before {
      opacity: 0;
      right: -5%;
      transition: all 0.8s ease-out;
    }
  }

  &.dark {
    background-color: $color-blue;
  }

  &.line {
    border: 1px solid $color-blue;
    background-color: $white;
    color: $color-blue;

    &::before {
      display: none;
    }

    &:hover {
      border: 1px solid $color-dark;
      color: $color-dark;
    }
  }

  &.default-line {
    border: 1px solid $color-blue-light;

    &:hover {
      border: 1px solid $white;
    }
  }
}
