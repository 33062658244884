@import "../../styles/_variables";

.card {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 316px;
  max-width: 660px;
  padding: 30px 70px;
  width: calc(48vw);

  .main-card {
    display: flex;
    align-items: unset;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .content {
      & > * {
        white-space: nowrap;
      }

      label {
        font-size: $font-size-label;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: $color-teal;
      }

      h1 {
        margin: 10px 0;

        width: 383px;
        height: 47px;

        font-weight: $font-weight-black;
        font-size: $font-size-card;
        /* identical to box height */
        letter-spacing: 0.035em;
        text-transform: uppercase;
      }

      p {
        white-space: unset !important;
        font-weight: $font-weight-light;
        font-size: $font-size-copy-small;
        line-height: 29px;
        color: $color-very-dark;
      }

      &.breakable {
        width: 50%;
      }
    }
  }
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05);

  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out, background-color 250ms ease-in-out;

  &:not(.disableHover) {
    &:hover {
      transform: scale(1.01);
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
      z-index: 1000;
    }
  }

  &.breakable {
    padding: 30px 0;
    &:not(:first-child) {
      margin-left: 20px;
    }

    justify-content: space-between;

    .main-card {
      width: calc(100% - 260px);

      .content {
        label,
        h1 {
          white-space: unset;
          height: auto;
        }

        p {
          font-size: $font-size-copy-smaller;
        }
      }
    }
  }
}
