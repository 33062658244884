@font-face {
  font-family: "Verlag";
  src: url("../assets/fonts/Verlag-Book.otf") format("opentype");
  font-weight: 325;
  font-style: normal;
}

@font-face {
  font-family: "Verlag";
  src: url("../assets/fonts/Verlag-Light.otf") format("opentype"),
    url("../assets/fonts/Verlag-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Verlag";
  src: url("../assets/fonts/Verlag-Bold.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Verlag";
  src: url("../assets/fonts/Verlag-Black.otf") format("opentype");
  font-weight: 450;
  font-style: normal;
}
