@import "../../styles/_variables";

.arrow-button {
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 10px;

  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: $black;

  img {
    transition: margin-left 250ms ease-in-out;
  }
  &:hover {
    img {
      margin-left: 20px;
    }
  }
}
